<template>
  <div>
    <h4 class="text-uppercase">Customer Details</h4>

    <CRow>
      <CCol sm="4" class="pr-0">
        <CCard class="px-4">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                src="/user.PNG"
                alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{ customer.first_name + " " + customer.last_name }}
              </h4>
              <p>UID #{{ customer.uid }}</p>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <li>
              <p>Conn. Type:</p>
              <p>
                {{ customer.connection_type }}
              </p>
            </li>
            <li>
              <p>Category:</p>
              <p>{{ customer.category }}</p>
            </li>
            <li>
              <p>Mobile No:</p>
              <p>{{ customer.mobile_number }}</p>
            </li>
            <li>
              <p>Email:</p>
              <p>{{ customer.email }}</p>
            </li>
            <li>
              <p>Mi Code:</p>
              <p>{{ customer.meter_inspector.mi_code }}</p>
            </li>
            <li>
              <p>Ward No:</p>
              <p>{{ customer.ward_number }}</p>
            </li>
            <li>
              <p>City:</p>
              <p>{{ customer.city }}</p>
            </li>
            <li>
              <p>State:</p>
              <p>{{ customer.state }}</p>
            </li>
            <li>
              <p>Country:</p>
              <p>{{ customer.country }}</p>
            </li>
            <li>
              <p>Address:</p>
              <p>{{ customer.address }}</p>
            </li>
          </ul>
        </CCard>
      </CCol>
      <CCol sm="8">
        <CCard>
          <CRow>
            <CCol sm="12">
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th scope="col">Meter ID</th>
                      <th scope="col">Meter Status</th>
                      <th scope="col">
                        Current <br />
                        Consumption
                      </th>
                      <th scope="col">
                        Last <br />
                        Reading At
                      </th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="text-center"
                      v-for="meter in meters"
                      :key="meter.id"
                    >
                      <td scope="row">
                        <router-link :to="'/meters/' + meter.id">
                          {{ meter.hardware_serial_no }}</router-link
                        >
                      </td>
                      <td scope="row">
                        <span
                          class="badge"
                          :class="
                            meter.status === 'METER_ASSIGNED'
                              ? 'badge-warning'
                              : meter.status === 'METER_INSTALLED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                        >
                          {{ meter.status }}</span
                        >
                      </td>
                      <td scope="row">{{ meter.current_consumption_unit }}</td>
                      <td scope="row">{{ meter.reading_date_time }}</td>

                      <td scope="row">
                        <!-- <CButton color="info" @click="$router.push('/customer/' + customer.id)" shape="pill">View</CButton> &nbsp; -->
                        <CButton
                          @click="showMeterStatusModal(meter)"
                          color="primary"
                          shape="pill"
                          >Update Status</CButton
                        >
                      </td>
                    </tr>
                  </tbody>
                  <!-- <tbody >
                    <tr>
                      <td colspan="9">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody> -->
                </table>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>

    <!-- <b-row :class="isMobile ? '' : 'pl-3 pr-3'">
      <b-col>
        <h3 class="heading-title">Customer's Detail</h3>
      </b-col>
    </b-row>

    <b-row v-if="!isMobileOnly" v-cloak>
      <b-col cols="11">
        <b-row>
          <b-col cols="4">
            <h5 style="padding-left: 15px">
              Name: {{ customer.first_name + " " + customer.last_name }}
            </h5>
          </b-col>
          <b-col cols="4">
            <h5>Email: {{ customer.email }}</h5>
          </b-col>
          <b-col cols="4">
            <h5>Mobile No: {{ customer.mobile_number }}</h5>
          </b-col>
          <b-col cols="4">
            <h5 style="padding-left: 15px">Address: {{ customer.address }}</h5>
          </b-col>
          <b-col cols="4">
            <h5>City: {{ customer.city }}</h5>
          </b-col>
          <b-col cols="4">
            <h5>State: {{ customer.state }}</h5>
          </b-col>
          <b-col cols="4">
            <h5 style="padding-left: 15px">
              Account Number: {{ customer.account_number }}
            </h5>
          </b-col>
          <b-col cols="4">
            <h5>Ward Number: {{ customer.ward_number }}</h5>
          </b-col>
          <b-col cols="4">
            <h5>Area: {{ customer.area }}</h5>
          </b-col>
          <b-col cols="4">
            <h5 style="padding-left: 15px">
              Connection Type: {{ customer.connection_type }}
            </h5>
          </b-col>
          <b-col cols="4">
            <h5>Category: {{ customer.category }}</h5>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="partner-custom-table-design" cols="12">
        <h4 class="heading-title px-3 mt-4">Meters</h4>
        <div class="table-responsive p-3">
          <table class="table table-striped">
            <thead>
              <tr>

                <th class="font-weight-bold">Meter Serial No</th>
                <th class="font-weight-bold">Current Status</th>
                <th class="font-weight-bold">Current Consumption Unit</th>
                <th class="font-weight-bold">Last Reading Time</th>

                <th class="font-weight-bold">Actions</th>
              </tr>
            </thead>
            <tbody v-if="meters.length">
              <tr v-for="meter in meters" :key="meter.id">

                <td>
                  <router-link class="text-info" :to="'/meters/' + meter.id"
                    >{{ meter.hardware_serial_no }}
                  </router-link>
                </td>
                <td>
                  <span
                    class="badge"
                    :class="
                      meter.status === 'METER_ASSIGNED'
                        ? 'badge-warning'
                        : meter.status === 'METER_INSTALLED'
                        ? 'badge-primary'
                        : 'badge-success'
                    "
                    >{{ meter.status }}</span
                  >
                </td>
                <td>{{ meter.current_consumption_unit }}</td>
                <td>
                  {{
                    meter.reading_date_time
                      ? dateFormat(meter.reading_date_time)
                      : "N/A"
                  }}
                </td>

                <td>
                  <a
                    href="javascript:void(0)"
                    @click="showMeterStatusModal(meter)"
                    class="btn btn-sm btn-primary"
                    >Update Status</a
                  >
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <h5 class="text-center">Meter Data Not Available.</h5>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row> -->

    <CModal
      :closeOnBackdrop="false"
      class="hide-footer custom-close-button"
      title="Update Meter Status"
      color="primary"
      :show.sync="updateStatusModal"
    >
      <UpdateMeterStatus
        :meter="meter"
        @closeUpdateMeterStatusModal="closeUpdateMeterStatusModal"
      ></UpdateMeterStatus>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateMeterStatus from "./partials/UpdateMeterStatus";
export default {
  name: "CustomerView",
  components: {
    UpdateMeterStatus,
  },

  data: () => {
    return {
      // customer: { first_name: "", last_name: "" },
      meterStatus: [],
      meter: {},
      updateStatusModal: false,
      customerId: Number,
      meterId: Number,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      deletingState: false,
      loadingState: false,
      role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      // isLoading: false,
      breadcrumbs: [
          { text: "Home", path: "/dashboard", isActive: false },
          {
              text: "Customer",
              path: `/customer`,
              isActive: false,
          },
          { text: "View", path: "", isActive: true },
      ],
    };
  },

  methods: {
    // getCustomer() {
    //   this.isLoading = true;
    //   try {
    //     this.axios
    //       .get(`/admin/customers/${this.customerId}`)
    //       .then((res) => {
    //         this.customer = res.data.data;
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       });
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },

    // getMeters() {
    //   this.isLoading = true;
    //   try {
    //     this.axios
    //       .get(`/admin/customers/${this.customerId}/meters`)
    //       .then((res) => {
    //         this.meters = res.data.data;
    //         this.isLoading = false;
    //       })
    //       .catch((err) => {
    //         console.log(err);
    //         this.isLoading = false;
    //       });
    //   } catch (err) {
    //     console.log(err);
    //     this.isLoading = false;
    //   }
    // },

    showMeterStatusModal(meter) {
      this.meter = meter;
      this.updateStatusModal = true;
    },

    closeUpdateMeterStatusModal(meter) {
      this.meterStatus = _.map(this.meters, (item) => {
        if (item.id === meter.id) {
          item = meter;
        }
        return item;
      });
      this.updateStatusModal = false;
    },

    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
  },
  computed: { ...mapGetters("Customer", ["customer", "meters"]) },
  mounted() {
    this.customerId = this.$route.params.id;
    this.$store.dispatch("Customer/getCustomerById", this.customerId);
    this.$store.dispatch("Customer/getMetersByCustomerId", this.customerId);
    // this.getMeters();
  },
  created() {
      this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px;
}
tbody:before {
  content: "@";
  display: block;
  line-height: 30px;
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>


<style lang="scss" scoped>
.avatar-container {
  max-width: 100px;
}
.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>

